<template>
    <div class="characters-page">
        <div class="diagonal-section character-header light-section">
            <h1>Meet the Characters</h1>
        </div>
        <div class="diagonal-section dark-section">
            <img class="character-image" src="https://jaykraussauthor.s3.us-east-1.amazonaws.com/leon.png"
                alt="Leon Jager" />
            <div class="character-info">
                <h2>Leon Jager</h2>
                <p>Leon Jäger is a 16th-century German knight thrust into a world unlike anything he’s ever known—a land
                    of magic, monsters, and strange new rules. The transition from steel and honor to a realm governed
                    by mysticism leaves him struggling with confusion and self-doubt. Yet Leon is no ordinary knight.
                    Intelligent, resourceful, and relentless, he adapts to this alien world step by step, learning its
                    secrets and forging a path forward. His journey takes a turn for the better when he meets companions
                    who slowly become his new-found family.</p>
                <p>Leon lives by a strict code: repay kindness tenfold, and meet insults with cold steel. His honor
                    binds him as tightly as his armor, but the weight of his past haunts him—visible in the scars etched
                    across his face, which he hides beneath his helmet whenever he is in public. These marks serve as
                    constant reminders of battles won but leave him self-conscious and guarded.</p>
                <p>Serious and stoic by nature, Leon keeps most at a distance. But to those he trusts, he reveals a
                    softer, more thoughtful side—one marked by quiet loyalty, unexpected kindness, and rare moments of
                    humor. His enemies, however, never survive long enough to witness this side of him. Beneath his grim
                    exterior lies a heart still capable of warmth, though scarred as deeply as his face. In a world that
                    challenges both his sword and spirit, Leon fights not only to survive but to forge a place for
                    himself—a knight without a kingdom, searching for purpose in an unfamiliar world.</p>
            </div>
        </div>
        <div class="diagonal-section light-section">
            <img class="character-image" src="https://jaykraussauthor.s3.us-east-1.amazonaws.com/hannah.png"
                alt="Hannah of Epione" />
            <div class="character-info">
                <h2>Hannah of Epione</h2>
                <p>A devoted cleric of Epione, the goddess of healing and medicine, Hannah is as skilled with her magic
                    as she is with her wit.</p>
                <p>With a vibrant personality and a sharp tongue, she’s the kind of companion who heals both wounds and
                    spirits in equal measure.</p>
                <p>Sassy, upbeat, and fiercely loyal, Hannah brings warmth and vitality wherever she goes—lifting her
                    friends when they fall and offering no mercy to those who stand against her.</p>
                <p>Whether mending broken bones or delivering cutting remarks, Hannah ensures that life, in all its
                    chaotic beauty, flourishes around her.</p>
                <p>Hannah is the first member of Leon's party, and integral to his new life.</p>
            </div>
        </div>
        <div class="diagonal-section dark-section">
            <img class="character-image" src="https://jaykraussauthor.s3.us-east-1.amazonaws.com/remora.png"
                alt="Kaitlin Remora" />
            <div class="character-info">
                <h2>Kaitlin Remora</h2>
                <p>An elven warrior and a Guildmaster of Adventurer's Guild, Kaitlin Remora is as formidable as she is
                    enigmatic.</p>
                <p>An accomplished warrior in her own right, with a past marked by loss, she wears a mask of
                    indifference and aggression to shield herself from further pain- but beneath that tough exterior
                    lies a heart that beats fiercely for those she holds dear.</p>
                <p>Kaitlin’s intensity makes her loyalty and kindness burn even brighter- offering unshakable support,
                    fierce protection, and a listening ear to her friends.</p>
                <p>Whether on the battlefield or in quiet moments of camaraderie, Kaitlin is a stalwart ally, friend,
                    and confidant, proving that true strength lies in both steel and compassion.</p>
            </div>
        </div>
        <div class="diagonal-section light-section">
            <img class="character-image" src="https://jaykraussauthor.s3.us-east-1.amazonaws.com/sataou.png"
                alt="Satoro Sataou" />
            <div class="character-info">
                <h2>Satoro Sataou</h2>
                <p>A grandmaster alchemist with over a thousand years of wisdom, Sataou carries his immense knowledge
                    lightly, preferring humor and wit over grand displays of power.</p>
                <p>To most, he is a quiet enigma—distant and indifferent, content to watch the world unfold from the
                    sidelines, but to those he considers family, he is warm and grandfatherly, always ready with a joke,
                    sage advice, or an unexpected lesson.</p>
                <p>Sataou rarely reveals the full extent of his abilities, avoiding spectacle at every turn, yet when
                    the moment demands it, even the earth seems to tremble in his presence, reminding all who witness it
                    that beneath his playful exterior lies power beyond comprehension.</p>
            </div>
        </div>
        <div class="diagonal-section dark-section">
            <img class="character-image" src="https://jaykraussauthor.s3.us-east-1.amazonaws.com/linmah.png"
                alt="Lin Mah" />
            <div class="character-info">
                <h2>Lin Mah</h2>
                <p>Lin Mah is an alchemist and a skilled archer, a quiet and reserved soul who keeps most at a distance.
                </p>
                <p>Beneath her calm exterior lies a formidable warrior, emerging only when the situation demands it.</p>
                <p>Though she may seem distant, those fortunate enough to call her a friend know the depths of her
                    affection and loyalty.</p>
                <p>In battle, her transformation is as precise as her arrows—swift, deliberate, and lethal.</p>
                <p>Lin moves through life with quiet grace, balancing the delicate art of creation with the fierce
                    resolve of a warrior when the time comes to protect those she holds dear.</p>
            </div>
        </div>
        <div class="diagonal-section light-section">
            <img class="character-image" src="https://jaykraussauthor.s3.us-east-1.amazonaws.com/elzbeth.png"
                alt="Elzbeth" />
            <div class="character-info">
                <h2>Elzbeth</h2>
                <p>Elzbeth is a sprite whose cheerful, bubbly nature makes her seem utterly harmless to most who meet her.</p>
                <p>With her small frame, playful demeanor, and boundless kindness, she’s often seen as someone easily overlooked—or taken advantage of.</p>
                <p>Only those who understand the true nature of sprites know the truth: Elzbeth carries immense power, far beyond what her innocent appearance suggests.</p>
                <p>Why she chooses to stay where she is remains a mystery—perhaps she’s running from something, or hiding from a past she wishes to forget.</p>
                <p>Though most around her dismiss her as inconsequential, anyone foolish enough to truly provoke her will discover the terrifying depths of her power—too late to regret it.</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.characters-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

/* Character Section */
.diagonal-section {
    padding: 140px 40px;
}

/* Image Styling */
.character-image {
    width: 300px;
    height: auto;
    object-fit: contain;
    margin-right: 2rem;
    border: black 3px solid;
    border-radius: 10px;
}

.character-header {
    padding-top: 70px!important;
    padding-bottom: 0px!important;
}

/* Character Info Styling */
.character-info {
    flex: 1;
}

.series-overview {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .diagonal-section {
        flex-direction: column;
        text-align: center;
        padding-top: 240px;
        padding-bottom: 240px;
    }

    .character-image {
        margin-right: 0;
        margin-bottom: 1.5rem;
    }
}
</style>