<template>
  <div class="about-me">
    <!-- Hero Section -->
    <div class="diagonal-section light-section hero-section">
      <img
            class="profile-image"
            src="https://jaykraussauthor.s3.amazonaws.com/jayauthorpicture.jpg"
            alt="Profile Image"
          />
      <p>Hello! I'm Jay, the author of both the Will of the Immortals and Privateer series.</p>
    </div>

    <!-- Biography Section -->
    <div class="diagonal-section dark-section">
      <section class="biography-section">
        <h2>My Story</h2>
        <p>I am a software developer, blacksmith, woodworker, mechanic, and author. 
           My journey into writing started years ago, and I have recently taken the terrifying step of publishing my work.</p>
        <p>
          I graduated from <strong>Valley Forge Military Academy and College</strong>, an experience that has greatly influenced my life both positively and negatively.
        </p>
        <p>
          I am happily married to the love of my life, celebrating more than five years married.
        </p>
      </section>
    </div>

    <!-- Works Section -->
    <div class="diagonal-section light-section">
      <section class="works-section">
        <h2>My Works</h2>
        <p>Learn more about some of the books I've written and the worlds I've built:</p>
        <ul>
          <li><strong>Will of the Immortals:</strong> A journey through cultivation and self-discovery.</li>
          <li><strong>Privateer:</strong> A spacefaring adventure across the stars.</li>
        </ul>
      </section>
    </div>

    <!-- Inspiration Section -->
    <div class="diagonal-section dark-section">
      <section class="inspiration-section">
        <h2>Inspiration</h2>
        <p>I draw inspiration from my varied passions and experiences, including blacksmithing, shooting, martial arts, and SCA activities. </p>
        <p>Anime, building custom PCs, and weightlifting also serve as creative outlets and improve my life.</p>
      </section>
    </div>

    <!-- Achievements Section -->
    <div class="diagonal-section light-section">
      <section class="achievements-section">
        <h2>Achievements</h2>
        <p>One of my proudest achievements is starting my journey as a published author, turning years of writing into books I can share with the world.</p>
        <p>In addition to writing, I take pride in my skill as a developer, craftsman, and mechanic, and enjoy the satisfaction of building things with my own hands.</p>
      </section>
    </div>
  </div>
</template>

<style scoped>
.about-me {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  text-align: center;
}

/* Hero Section */
.hero-section {
  margin-top: 0;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-section p {
  font-size: 1.2rem;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

/* Social Links */
.socials-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.socials-links a {
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.socials-links a:hover {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .diagonal-section-top,
  .diagonal-section-bottom {
    padding: 3rem 1rem;
  }
}
</style>
