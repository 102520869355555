<template>
  <div class="series-page">
    <!-- Series Overview Section -->
    <div class="diagonal-section light-section">
      <section class="series-overview">
        <img class="series-image" src="https://jaykraussauthor.s3.amazonaws.com/willoftheimmortalsseriesimage.png"
          alt="Series Image" />
        <p>
          Will of the Immortals is an epic cultivation fantasy following Leon Jager, a 1500s German knight, as he
          journeys through a mystical world of magic, gods, and Qi.
        </p>
      </section>
    </div>
    <div class="button-section">
      <div class="character-button">
        <router-link to="/will-of-the-immortals-characters" class="btn">Meet the Characters</router-link>
      </div>
    </div>
    <!-- Books Section -->
    <div class="diagonal-section dark-section">
      <section class="books-section">
        <div class="book">
          <img src="https://jaykraussauthor.s3.amazonaws.com/SteelFoundationsEbookCover.png" alt="Steel Foundations"
            class="book-cover" />
          <div class="book-info">
            <h2>Steel Foundations</h2>
            <p>Steel Foundations asks the question: What if a 16th-century German knight were to be thrust into a world
              of magic, gods, and monsters, forced to navigate the unknown while mourning the loss of everything he once
              knew?</p>
            <p>Leon Jäger, a stoic and unyielding knight, lives by a simple code: repay kindness with a river of
              ambrosia, and slights with total destruction. But when an unexplainable force pulls him into a foreign
              world where magic flows and monsters roam, his creed will be tested like never before.</p>
            <p>Struggling to comprehend powers beyond his imagining, Leon must master the mystical art of Qi cultivation
              while hiding his abilities from those who might exploit them. As he maneuvers through this strange land of
              gods, magic, and Cultivation, Leon must not only survive but carve out his place—without losing himself in
              the process.</p>
            <p>Steel Foundations blends the epic drama of western fantasy with the intricate philosophy of Chinese
              cultivation, wrapped in the fast-paced thrills of LitRPG. Swords, sorcery, and mystical forces converge in
              this unique isekai adventure, where eastern tradition meets a knight’s resolve.</p>
            <a class="purchase-link" href="https://www.amazon.com/gp/product/B0DHJCNBWM" target="_blank">Purchase on
              Amazon</a>
          </div>
        </div>
        <hr />
        <div class="book">
          <img src="https://jaykraussauthor.s3.amazonaws.com/runicoceancover.png" alt="Runic Ocean"
            class="book-cover" />
          <div class="book-info">
            <h2>Runic Ocean</h2>
            <p>Runic Ocean plunges deeper into the mystical and treacherous world of Qi, Cultivation, and gods. Leon
              Jäger, still adjusting to life in this strange land, finds himself drawn toward new horizons—and new
              dangers—when the winds of fate guide him to the cliffside city of Wavecrest. But even a knight hardened by
              war and tempered by cultivation must tread carefully, for the cliffs of Wavecrest conceal more than just
              crashing waves.</p>
            <p>Just as Leon starts to find his footing, someone close to him disappears, forcing him into a desperate
              search through a city rife with deceit and ambition. As he and his companions follow the trail, they
              uncover power struggles that threaten not just their mission, but their lives. To make matters worse,
              Leon's enemies are not only numerous but some are far beyond his current abilities, leaving him no choice
              but to outthink, outmaneuver and, potentially, endure brutal defeat.</p>
            <p>Haunted by the toll of past battles and loss, Leon’s path as a Cultivator begins to falter under the
              effects of Qi Deviation—a dangerous imbalance that threatens to unravel both his cultivation and his
              sanity. With every misstep, the line between friend and foe blurs, and Leon must fight to hold on to who
              he is—or risk being consumed by the very power he seeks to master.</p>
            <p>Runic Ocean continues the immersive blend of western fantasy, Chinese cultivation, and LitRPG adventure
              introduced in Steel Foundations. With enemies both known and unseen, battles fought with more than just
              weapons, and a relentless storm rising on every front, Leon must evolve— or be swept beneath the tides.
            </p>
            <a class="purchase-link" href="https://www.amazon.com/gp/product/B0DKV7M2XL" target="_blank">Purchase on
              Amazon</a>
          </div>
        </div>
        <hr />
        <div class="book">
          <img src="https://jaykraussauthor.s3.us-east-1.amazonaws.com/ironheartcrucibleebookcover.png"
            alt="Runic Ocean" class="book-cover" />
          <div class="book-info">
            <h2>Ironheart Crucible</h2>
            <p>Ironheart Crucible takes Leon Jäger and his companions into the vast and merciless desert, where
              survival depends on more than strength—it demands resilience, strategy, and an iron will. At the heart
              of the endless sands lies Oasis, a bustling town built around a vital spring, where treachery and
              ambition thrive beneath the surface of its shifting sands.</p>
            <p>Drawn by promises of opportunity and peril, Leon must navigate a dangerous path through both a
              treacherous dungeon and a martial tournament, where the stakes are nothing short of life and death.
              Faced with opponents who test his skill and enemies who surpass him in power- both in Cultivation and
              politics, Leon must find a way to endure and prevail.</p>
            <p>But survival alone will not be enough. To face the trials ahead, Leon must break through to the elusive
              Qi Compression realm, a process that will push him to his limits and force him to confront the very
              nature of his cultivation—and his own concept of the truths behind Qi itself. Each step forward brings
              new revelations and challenges that will shape not only his future, but the fates of all who stand
              beside him.</p>
            <a class="purchase-link"
              href="https://www.amazon.com/Ironheart-Crucible-Will-Immortals-Book-ebook/dp/B0DPG7XYZ8L"
              target="_blank">Purchase on Amazon</a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<style scoped>
.series-overview {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 10px;
}

.hero-section {
  margin-top: 0;
}

.button-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark-section {
  background-color: #333;
  color: white;
  min-height: 400px;
}

hr {
  margin-top: 40px;
  margin-bottom: 50px;
}

@media (max-width: 768px) {
  .books-section {
    padding-top: 175px;
    padding-bottom: 125px;
  }

  .book {
    flex-direction: column;
    text-align: center;
  }

  .book-cover {
    width: 80%;
    margin-bottom: 1rem;
  }
}
</style>
